.brand-banner{
  background-repeat:no-repeat;
  background-size: cover;
  background-position: center center;
  width:100%;
  min-height: 300px;
}
.brand-container{
  .MuiBreadcrumbs-separator{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  background-color: white;
  .link{
    font-size: 12px;
    display: flex;
  }
  .icon{
    margin-right: 0.5px;
    width: 20px;
    height: 20px;
  }
}
.MuiBreadcrumbs-li{
  .anchorTag:hover{
    color:#014c8c !important;
    cursor: pointer;
    text-decoration: underline !important;
  }
  .link{
    cursor: default;
  }
}
@media screen and (max-width: 600px){
  .makeStyles-image-2{
    margin-bottom: 20px !important;
  }
  .makeStyles-image-2:last-child{
    margin-bottom: 0 !important;
  }
}

