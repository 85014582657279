.time{
  font-weight: bolder;
  border-bottom:0.5px solid #eaeaea
}
.hide{
  transition: opacity 4s ease-out;
  opacity: 0;
  height: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow: hidden;
}
.time:last-child{
  border-bottom:unset
}
.MuiTypography-h5{
  font-size: 15px !important;
  font-weight: bold !important;
}
.employeeContainer{

  display: flex;
  justify-content: center;
  .imageContainer{
    display: flex;
    justify-content: center;
  }
}
