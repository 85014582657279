@import 'src/Components/Common/Header/Header.scss';
@import 'src/Components/Common/Footer/Footer.scss';
@import 'src/Components/HomePage/HomePage.scss';
@import 'src/Components/Brand/Brand.scss';
@import "src/Components/Common/BodyContainer/BodyContainer.scss";
@import 'src/Components/Employee/Employee.scss';
@import "src/Components/Form/Form.scss";
@import "variables.scss";

.container{
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  &-middle{
    padding-top: 10px;
    padding-bottom: 180px;
  }
}
@media screen and (min-width: 1200px) {
  .container{
    width: 1170px;
  }
}

html {
  font-family: 'Raleway', sans-serif !important;
  position: relative;
  min-height: 100%;
}
.white{
  background-color: white;
}
body {
  background-color: #8E8E8E;
  margin: 0 0 100px;

}


h4{
  color:#000;
  text-align: center;
  font-size:20px;
  line-height: 28px;
  font-weight: bold;
  padding-bottom: 30px;
}

.MuiButton-contained.Mui-disabled{
  color: #9E9E9E !important;
}
@font-face {
  font-family: 'Jaguar';
  src: local('JaguarModernWeb-Regular'), url('./fonts/Jaguar/JaguarModernWeb-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Jaguar-Secondary';
  src: local('ProximaNova-Regular'), url('./fonts/Jaguar/ProximaNova-Regular.woff') format('woff');
}
@font-face {
  font-family: 'LandRover';
  src: local('LandRoverWeb'), url('./fonts/LandRover/LandRoverWeb-Medium.woff') format('woff');
}
@font-face {
  font-family: 'LandRover-Secondary';
  src: local('AvenirNextLTPro-Regular'), url('./fonts/LandRover/AvenirNextW01-Regular.woff') format('woff');
}

.jaguar-font-Main{
  font-family: Jaguar !important;
}
.jaguar-font-Secondary{
  font-family: Jaguar-Secondary !important;
}
.landrover-font-Main{
  font-family: LandRover !important;
}
.landrover-font-Secondary{
  font-family: LandRover-Secondary !important;
}
