@import "src/variables.scss";
.brands-container{
  p{
    text-align: center;
  }
  padding-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  .row{
    text-align: center;
    align-items: center;
    .col{
      align-self: center;
    }
  }
}
.logo-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 230px;
  justify-content: space-around;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  &-image{
    margin-bottom: 20px;
    width: 25rem;
    display: flex;
    justify-content: center;

  }
img{
  max-width:100%;
  max-height:100%;
  object-fit: contain;
}
}
.landrover-button{
  color:white !important;
  background-color:$landrover-green-color !important;
}
.jaguar-button{
  color:white !important;
  background-color:$jaguar-red-color !important
}
@media screen and(max-width: 767px){
  .brands-container{
    .row{
      img:last-child{
        margin-top:20px !important;
      }
      .col:last-child{
        margin-top: 40px;
      }
    }
  }

}

/* This fires as soon as the element enters the dorm */
.list-transition-enter,
.list-transition-appear {
  /*We give the list the initial dimension of the list button*/
  opacity: 0.1;
  color: transparent;

}
/* This is where we can add the transition*/
.list-transition-enter-active{
  opacity: 0.3 ;
  transition: all 300ms;
}
.list-transition-appear-active {
  opacity: 1 ;
  transition: all 500ms;
}

/* This fires as soon as the this.state.showList is false */
.list-transition-exit {

}
/* fires as element leaves the DOM*/
.list-transition-exit-active {
  top: 0;
  width: 120px;
  max-height: 40px;
  color: transparent;
  transition: all 400ms;
}

.brandImage{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.2;
  object-fit: cover;
}
